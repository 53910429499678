import StarIcon from "@material-design-icons/svg/filled/star.svg";
import EmptyStarIcon from "@material-design-icons/svg/outlined/star_border.svg";
import MuiRating from "@material-ui/lab/Rating";
import classNames from "classnames";
import * as React from "react";

import LightTooltip from "~/components/core/Tooltip";

interface Props {
  rating: number;
  noTooltip?: boolean;
  className?: string;
}

const Rating: React.FC<Props> = ({ rating, noTooltip, className }) => {
  const content = (
    <div className={classNames("h-[24px] flex gap-[4px]", className)}>
      <MuiRating
        value={rating}
        precision={0.1}
        readOnly
        icon={
          <StarIcon className="w-[24px] h-[24px] text-goldStar-500 fill-current" />
        }
        emptyIcon={
          <EmptyStarIcon className="w-[24px] h-[24px] text-gray-800 fill-current" />
        }
      />
    </div>
  );
  if (noTooltip) {
    return content;
  }
  return (
    <LightTooltip
      title={rating ? rating.toFixed(1) : ""}
      placement="top"
      arrow
      enterTouchDelay={0}
    >
      {content}
    </LightTooltip>
  );
};

export default Rating;
