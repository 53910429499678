import CloseIcon from "@material-design-icons/svg/filled/close.svg";
import Drawer from "@material-ui/core/Drawer";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";
import { noop } from "lodash";
import * as React from "react";

import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";

import { Button } from "../Button";

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  isDashboard?: boolean;
}

const FilterToolbarAdaptiveContent = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className, isDashboard }, ref) => {
    const theme = useTheme();
    const mobileView = useMediaQuery<boolean>(theme.breakpoints.down("xs"));
    const { filtersDrawerOpen, onToggleFiltersDrawer } = React.useContext(
      AccountLayoutContext
    );

    React.useEffect(() => {
      const el = document.getElementById("filter-toolbar");
      const handleScroll = () => {
        if (el) {
          const stickyTop = parseInt(window.getComputedStyle(el).top);
          const currentTop = el.getBoundingClientRect().top;
          el.classList.toggle("shadow-under", currentTop === stickyTop);
        }
      };
      if (isDashboard) {
        window.addEventListener("scroll", handleScroll);
      }
      return () => window.removeEventListener("scroll", handleScroll);
    }, [isDashboard]);

    if (!mobileView) {
      return (
        <div
          id="filter-toolbar"
          ref={ref}
          className={classNames(
            "flex min-h-[64px] py-[24px] px-[12px] sm:px-[32px] paragraph dashboardFilterToolbar",
            isDashboard
              ? "items-center justify-between bg-cream-100 sticky top-0 z-[1000]"
              : "relative items-end gap-[16px] flex-wrap"
          )}
        >
          {children}
        </div>
      );
    }

    return (
      <Drawer
        ref={ref}
        open={filtersDrawerOpen}
        anchor="right"
        className={className}
        onClose={onToggleFiltersDrawer}
      >
        <div className="min-w-[350px] p-[10px]">
          <div className="flex justify-end w-full">
            <Button
              variant="wrapper"
              action={onToggleFiltersDrawer || noop}
              aria-label="Close filters drawer"
            >
              <CloseIcon
                className="w-[24px] h-[24px] text-navy-900 fill-current"
                aria-hidden
              />
            </Button>
          </div>
          {children}
        </div>
      </Drawer>
    );
  }
);

export default FilterToolbarAdaptiveContent;
