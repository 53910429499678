import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import FilterToolbarAdaptiveContent from "~/components/core/FilterToolbarAdaptiveContent";
import { PERIOD, PERIODS } from "~/constants/dates";
import { RANGE_GETTERS } from "~/constants/filters";
import { Period, Range } from "~/declarations/filters";
import {
  getSchoolYearRange,
  schoolYearFilterLabel
} from "~/utils/schoolYearHelpers";

import { SelectDates } from "./SelectDates";

interface Props {
  selectedTab?: PERIOD;
  onChange?: (period: Period, range: Range) => void;
  children?: React.ReactNode;
  className?: string;
  rangeFilter?: Range;
  isDashboard?: boolean;
}

const FilterToolbar = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      onChange,
      selectedTab,
      className,
      children,
      rangeFilter,
      isDashboard
    }: Props,
    ref
  ) => {
    const { schoolYearStartDate } = useRequiredAuthContext();
    const [schoolYearRange, setSchoolYearRange] = React.useState<
      Range | undefined
    >(undefined);

    const handleSelectPeriod = (period: string) => {
      const newPeriod = PERIODS.find(x => x.period === period);
      if (newPeriod && newPeriod.period !== PERIOD.custom) {
        if (
          [PERIOD.currentYear, PERIOD.previousYear].includes(newPeriod.period)
        ) {
          const range = getSchoolYearRange(
            schoolYearStartDate,
            newPeriod.period === PERIOD.currentYear
          );
          setSchoolYearRange(range);
          onChange?.(newPeriod, range);
        } else {
          setSchoolYearRange(undefined);
          const range = RANGE_GETTERS[newPeriod.period];
          if (range) {
            onChange?.(newPeriod, range());
          }
        }
      }
    };

    const handleChangeCustomPeriod = (range: Range) => {
      setSchoolYearRange(undefined);
      onChange?.({ period: PERIOD.custom, title: "Custom" }, range);
    };

    const periodOptions = React.useMemo(() => {
      return PERIODS.map(period => ({
        value: period.period,
        label: schoolYearFilterLabel(period.title, schoolYearStartDate)
      }));
    }, [schoolYearStartDate]);

    const selectedOption = selectedTab
      ? PERIODS.find(period => period.period === selectedTab)
      : undefined;

    return (
      <FilterToolbarAdaptiveContent
        className={className}
        ref={ref}
        isDashboard={isDashboard}
      >
        {selectedOption && (
          <SelectDates
            selectedOption={{
              value: selectedOption.period,
              label: selectedOption.title
            }}
            options={periodOptions}
            onChange={handleSelectPeriod}
            handleChangeCustomPeriod={handleChangeCustomPeriod}
            initialRange={rangeFilter ?? [null, null]}
            rangeFilter={rangeFilter}
            schoolYearRange={schoolYearRange}
          />
        )}
        {children}
      </FilterToolbarAdaptiveContent>
    );
  }
);

export default FilterToolbar;
